@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&family=Poppins:wght@400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

ul, li  {
  list-style: none;
}

body {
  font-family: 'Poppins', sans-serif;
}

.padding-xy {
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 768px) {
  .padding-xy {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.btn-cta {
  background-color: hsl(180, 66%, 49%);
  color: whitesmoke;
  padding: .5rem .8rem;
  font-weight: 500;
  font-size: .85rem;
}


/* Header  */

header {
  padding-top: 2.5rem;
}
@media (max-width: 768px) {
  header {
    padding-top: 1.5rem;
  }
}


/* Hero */
.hero {
  overflow: hidden;
  padding-bottom: 9rem;
}

.hero-image {
  width: 130%;
}

.title {
  width: 80%;
  margin: auto;
  font-weight: 700;
}

.subtitle {
  color: rgb(158,154,167);
  width: 85%;
  font-weight: 500;
  text-align: center;
  margin: auto;
}

@media (min-width: 900px) {
  .hero {
    padding-left: 9%;
    overflow: hidden;
    padding-bottom: 8rem;
  }
  .hero-inner {
    grid-template-columns: 45% 55%;
  }
  .site-desc {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .hero .title {
    text-align: start;
    margin: 0;
    font-size: 5rem;
    line-height: 4.5rem;
  }
  .hero .subtitle {
    text-align: start;
    margin: 0;
    font-size: 1.1rem;
  }
}

/* Main  */

main {
  background-color: rgb(239,240,245);
  padding-top: 8rem;
  position: relative;
}

.stats .title {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
}

.stats .subtitle {
  max-width: 30rem;
  text-align: center;
  margin: auto;
}


.cta {
  background: url('./images/bg-boost-mobile.svg');
  background-color: hsl(257, 27%, 26%);
  height: 12rem;
  background-size: contain;
}

.cta h2 {
  max-width: 100%;
}

.cta .btn-cta {
  padding: .7rem .4rem;
  font-size: 1rem;
  width: 9rem;
}

.btn-cta:hover {
  opacity: .75;
  transition: all;
  transition-duration: 200ms;
}

@media (min-width: 768px) {
  main {
    padding-top: 7rem;
  }
  .cta {
    background: url('./images/bg-boost-desktop.svg');
    background-color: hsl(257, 27%, 26%);
    background-size: cover;
  }
  .stats {
    padding-bottom: 5rem;
  }
}

/* Shortener  */
.shortener {
  background: url('./images/bg-shorten-desktop.svg');
  background-color: hsl(257, 27%, 26%);
  width: 84%;
  margin: auto;
  margin-bottom: 4rem;
  border-radius: .375rem;
  display: grid;
  place-items: center;
  padding:2.5rem 1.5rem;

  position: absolute;
  top: -4.5rem;
  left: 50%;
  transform: translate(-50%);
}

.shortener * {
  border-radius: .5rem;
}

form {
  width: 92%;
  min-height: 42%;
  display: grid;
  grid-template-columns: auto 14%;
  gap: 1.4rem;
  box-sizing: border-box;
}

input {
  width: 100%;
  height: 110%;
  padding: 1rem;
}

form .btn-cta {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

.warning {
  display: none;
  color: red;
}

@media (max-width: 900px) {
  .shortener {
    background-image: url('./images/bg-shorten-mobile.svg');
    background-size: cover;
    width: calc(100% - 8%);
    padding: 2rem 1rem;
    top: -6rem;
  }
  form {
    grid-template-columns: 1fr;
  }
}


/* List  */

.list-item {
  background-color: white;
  border-radius: .375rem;
  width: 84%;
  margin: 0 auto;
  padding: 1rem 1.6rem;
  display: grid;
  grid-template-columns: auto 8.5%;
  gap: 1.2rem
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* overflow: scroll; */
}

.btn-copy {
  font-size: 1.2rem;
  border-radius: .5rem;
}

.btn-cross {
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.btn-cross:hover {
  cursor: pointer;
}

.short-url {
  color: hsl(180, 66%, 49%);
}

.short-url:hover, .full-url:hover {
   opacity: .5;
}

@media (max-width: 900px) {
  .list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    overflow: hidden;
  }

  .short-url {
    width: 100%;
    text-align: start;
  }

  .full-url {
    width: 100%;
    text-align: start;
  }

  .btn-copy {
    width: 100%;
  }
}
